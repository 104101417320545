import { createGlobalStyle, css } from 'styled-components'
import { theme } from '../../utils/theme'

const removeMargin = css`
	margin: 0;
	padding: 0;
`

const TypographyStyle = createGlobalStyle`

  html, body {
    font-family: ${theme.fontFamilies.default};
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: ${theme.fontSizes.p4};
    line-height: ${theme.lineHeights.p4};
    color: ${theme.colors.textColor};
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-wrap: break-word;
  }

  h1 {
  	${removeMargin};
    font-size: ${theme.fontSizes.h1};
    font-weight: normal;
    line-height: ${theme.lineHeights.h1};
    margin-bottom: ${theme.margins.XSmall};
    letter-spacing: -1px;
    max-width: ${theme.widths.textMaxWidth};
  }

  h2 {
    ${removeMargin};
    font-size: ${theme.fontSizes.h2};
    line-height: ${theme.lineHeights.h2};
    margin-bottom: ${theme.margins.small};
    max-width: ${theme.widths.textMaxWidth};
    font-weight: normal;
  }

  h3 {
    ${removeMargin};
    font-size: ${theme.fontSizes.h3};
    font-weight: normal;
    line-height: ${theme.lineHeights.h3};
    margin-bottom: 10px;
  }

  p {
    white-space: pre-line;
    max-width: ${theme.widths.textMaxWidth};
    ${removeMargin};
  }

  a {
    background-color: transparent;
    text-decoration: none;
    color: ${theme.colors.textColor};
  }

  a:active,
  a:hover {
    outline: 0;
    text-decoration: none;
    color: ${theme.colors.textColor};
  }

  p,
  h2 {
    a {
      text-decoration: underline;
    }

    a:active,
    a:hover {
      text-decoration: underline;
      color: ${theme.colors.textColor};
    }
  }

  /* Hide the webkitfont before it has been loaded, to avoid visual jumping of the font */
  .wf-loading html,
  .wf-loading body,
  .wf-loading .host-extras,
  .wf-loading .becomehost-button {
    visibility: hidden;
  }

`

export default TypographyStyle
