import { createGlobalStyle } from 'styled-components'
import { theme } from '../../utils/theme'

const DatePickerStyle = createGlobalStyle`
  .pickadate--element.pickadate--root {
    color: ${theme.colors.textColor} !important;
    button {
      background: ${theme.colors.lightGray} !important;
    }
    svg {
      fill: ${theme.colors.textColor} !important;
    }
  }
  
  .pickadate--grid_cell__disabled > div, .pickadate--grid_cell__disabled:hover > div {
    background: ${theme.colors.gray} !important;
  }
  
  .pickadate--grid_cell:hover > div, .pickadate--grid:focus:not(:active) .pickadate--grid_cell__highlighted:not(.pickadate--grid_cell__selected) > div, .pickadate--grid__focused:not(:active) .pickadate--grid_cell__highlighted:not(.pickadate--grid_cell__selected) > div {
    background: ${theme.colors.orange} !important;
    color: white !important;
	  opacity: 0.8 !important;
  }
  
  .pickadate--grid_cell__selected > div, .pickadate--grid_cell__selected:hover > div {
    background: ${theme.colors.orange} !important;
  }
  
  button.pickadate--element.pickadate--grid {
    border-radius: 7px;
  }
  
  .pickadate--element.pickadate--body {
    padding: 0;
  }
  
  button.pickadate--element.pickadate--button {
    border-radius: 0;
    border: 0;
  }
  
  .pickadate--grid_cell__today:hover:not(.pickadate--grid_cell__disabled) > div, .pickadate--grid:focus .pickadate--grid_cell__today.pickadate--grid_cell__highlighted:not(.pickadate--grid_cell__selected) > div, .pickadate--grid__focused .pickadate--grid_cell__today.pickadate--grid_cell__highlighted:not(.pickadate--grid_cell__selected) > div, .pickadate--grid_cell__today.pickadate--grid_cell__selected > div {
    border-color: ${theme.colors.textColor} !important;
  }
`

export default DatePickerStyle
