import PropTypes from 'prop-types'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

// Extracts the min and/or max widths from our theme breakpoints, since MediaQuery requires a different format.
const regex = /(\(min-width: \d+px\) and \(max-width: \d+px\))|(\(min-width: \d+px\))|(\(max-width: \d+px\))/g

const Breakpoint = ({ breakpoint, children }) => {
	const query = useMediaQuery({ query: breakpoint?.match(regex)[0] })
	return <>{query && children}</>
}

Breakpoint.propTypes = {
	breakpoint: PropTypes.string.isRequired,
}

export default Breakpoint
