import { isServerRendering } from '../utils/applicationHelper'

let location = {}

export const setLocation = railsContext => {
	location.host = railsContext.host
	location.href = railsContext.href
	location.location = railsContext.location
	location.search = railsContext.search
	location.pathname = railsContext.pathname
	location.port = railsContext.port
	location.origin = railsContext.origin
}

const useLocation = () => (isServerRendering() ? location : window.location)

export default useLocation
