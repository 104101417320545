// TODO: Perhaps make theme into a JSON.parse for performance reasons, since it's a pretty large json
// Using rgb/rgba for IE support when adding opacity in function fadeColor
const colors = {
	green: 'rgb(98, 175, 0)',
	black: 'rgb(17, 17, 17)', // #111111
	darkGray: 'rgb(128, 128, 128)', // #808080
	gray: 'rgb(241, 241, 241)', // #F1F1F1
	lightGray: 'rgb(248, 248, 248)', // #F8F8F8
	orange: 'rgb(234, 129, 70)', // #EA8146
	gold: 'rgb(146, 127, 69)', // #927F45
	yellow: 'rgb(253, 201, 16)', // #FDC910
	red: 'rgb(255, 0, 11)', // #FF000B
	white: 'rgb(255, 255, 255)',
}
const colorTypes = {
	textColor: colors.black,
	lightTextColor: colors.darkGray,
	errorColor: colors.red,
	warningColor: colors.orange,
	successColor: colors.green,
	// buttonColor: colors.midnightBlue,
	// buttonHoverColor: colors.midnightLightBlue,
	// linkColor: colors.orange,
	// linkHoverColor: colors.orange,
}
export const theme = {
	colors: {
		...colors,
		...colorTypes,
		fade: (color, opacity = 0.5) => color.replace(')', `, ${opacity})`).replace('rgb', 'rgba'),
	},
	fontSizes: {
		h1: '40px',
		h2: '20px',
		h3: '18px',
		p5: '12px',
		p4: '15px',
		p3: '16px',
		p2: '20px',
		p1: '40px',
	},
	lineHeights: {
		h1: '40px',
		h2: '24px',
		h3: '22px',
		p5: '15px',
		p4: '20px',
		p3: '20px',
		p2: '24px',
		p1: '40px',
	},
	fontFamilies: {
		default: "'GT America', Helvetica, sans-serif",
	},
	// Int version of margins can be used for calculations
	margins: {
		XXSmall: '5px',
		XXSmallInt: 5,
		XSmall: '10px',
		XSmallInt: 10,
		small: '20px',
		smallInt: 20,
		large: '40px',
		largeInt: 40,
		XLarge: '80px',
		XLargeInt: 80,
	},
	paddings: {
		XXSmall: '5px',
		XXSmallInt: 5,
		XSmall: '10px',
		XSmallInt: 10,
		small: '20px',
		smallInt: 20,
		large: '40px',
		largeInt: 40,
		XLarge: '80px',
		XLargeInt: 80,
		XXLarge: '120px',
		XXLargeInt: 120,
	},
	widths: {
		pageMaxWidthIncludingPadding: '1270px', // Max width for content on large screens after page padding has been applied
		pageMaxWidthIncludingPaddingInt: 1270,
		pageMaxWidthExcludingPadding: '1510px', // Max width for content on large screens before page padding has been applied
		pageMaxWidthExcludingPaddingInt: 1510,
		wideContentMaxWidth: '1920px', // Max width for wide content which normally stretches to the end of the screen
		wideContentMaxWidthInt: 1920,
		narrowPageMaxWidth: '430px', // Max width for content on pages with very narrow content, e.g. purchase page
		textMaxWidth: '740px', // Max width for content on pages with very narrow content, e.g. purchase page
		stripeMaxWidth: '402px', // Max width for stripe embedded components
	},
	breakpoints: {
		phone: {
			upToAndExcluding: '@media only screen and (max-width: 0px)',
			range: '@media only screen and (min-width: 0px) and (max-width: 425px)',
			upToAndIncluding: '@media only screen and (max-width: 425px)',
			overAndExcluding: '@media only screen and (min-width: 426px)',
			inverted: '@media only screen and (min-width: 426px)',
			minInt: 0,
			maxInt: 425,
		},
		tablet: {
			upToAndExcluding: '@media only screen and (max-width: 425px)',
			range: '@media only screen and (min-width: 426px) and (max-width: 768px)',
			upToAndIncluding: '@media only screen and (max-width: 768px)',
			overAndExcluding: '@media only screen and (min-width: 769px)',
			inverted: '@media only screen and not ((min-width: 426px) and (max-width: 768px))',
			minInt: 426,
			maxInt: 768,
		},
		laptop: {
			upToAndExcluding: '@media only screen and (max-width: 768px)',
			range: '@media only screen and (min-width: 769px) and (max-width: 1024px)',
			upToAndIncluding: '@media only screen and (max-width: 1024px)',
			overAndExcluding: '@media only screen and (min-width: 1025px)',
			inverted: '@media only screen and not ((min-width: 769px) and (max-width: 1024px))',
			minInt: 769,
			maxInt: 1024,
		},
		largeLaptop: {
			upToAndExcluding: '@media only screen and (max-width: 1024px)',
			range: '@media only screen and (min-width: 1025px) and (max-width: 1240px)',
			upToAndIncluding: '@media only screen and (max-width: 1240px)',
			overAndExcluding: '@media only screen and (min-width: 1241px)',
			inverted: '@media only screen and not ((min-width: 1025px) and (max-width: 1240px))',
			minInt: 1025,
			maxInt: 1240,
		},
		monitor: {
			upToAndExcluding: '@media only screen and (max-width: 1240px)',
			range: '@media only screen and (min-width: 1241px) and (max-width: 1509px)',
			upToAndIncluding: '@media only screen and (max-width: 1509px)',
			overAndExcluding: '@media only screen and (min-width: 1510px)',
			inverted: '@media only screen and not ((min-width: 1241px) and (max-width: 1509px))',
			minInt: 1241,
			maxInt: 1509,
		},
		largeMonitor: {
			upToAndExcluding: '@media only screen and (max-width: 1509px)',
			range: '@media only screen and (min-width: 1510px) and (max-width: 2560px)',
			upToAndIncluding: '@media only screen and (max-width: 2560px)',
			overAndExcluding: '@media only screen and (min-width: 2561px)',
			inverted: '@media only screen and not ((min-width: 1510px) and (max-width: 2560px))',
			minInt: 1510,
			maxInt: 2560,
		},
		tv: {
			upToAndExcluding: '@media only screen and (max-width: 2560px)',
			range: '@media only screen and (min-width: 2561px)',
			inverted: '@media only screen and (max-width: 2560px)',
			minInt: 2561,
			maxInt: 3840, // 4k TV width
		},
	},
}
