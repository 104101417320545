import styled from 'styled-components'
import React from 'react'
import { theme } from '../../utils/theme'
import { SmallPageSection } from './layout/PageComponents'
import { callToAction, text, textColors } from './PageMixins'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'

// Can be used within a text component to change the color for a specific part
export const TextSpan = styled.span`
	${props => text(props)}
`
export const Text = styled.p`
	${props => text(props)}
	margin-bottom: ${({ useAsTitle }) => (useAsTitle ? theme.margins.XSmall : 0)};
`
export const P5 = styled(Text)`
	font-size: ${theme.fontSizes.p5};
	line-height: ${theme.lineHeights.p5};
`
export const B5 = styled(P5)`
	font-weight: bold;
`
export const P4 = styled(Text)`
	font-size: ${theme.fontSizes.p4};
	line-height: ${theme.lineHeights.p4};
`
export const B4 = styled(P4)`
	font-weight: bold;
`
export const P3 = styled(Text)`
	font-size: ${theme.fontSizes.p3};
	line-height: ${theme.lineHeights.p3};
`
export const B3 = styled(P3)`
	font-weight: bold;
`
export const P2 = styled(Text)`
	font-size: ${theme.fontSizes.p2};
	line-height: ${theme.lineHeights.p2};
`
export const B2 = styled(P2)`
	font-weight: bold;
`
export const P1 = styled(Text)`
	font-size: ${theme.fontSizes.p1};
	line-height: ${theme.lineHeights.p1};
	letter-spacing: -1px;
`
export const B1 = styled(P1)`
	font-weight: bold;
`

export const StyledCallToAction = styled.span`
	& svg {
		width: 16px;
		height: 16px;
		margin-bottom: -4px;
	}
	${props => callToAction(props)}
`
// Wrap CTA text children in spans so we can style them with css
export const CallToAction = ({ children, ...props }) => (
	<StyledCallToAction {...props}>
		{React.Children.map(children, child => (typeof child === 'string' ? <span>{child}</span> : child))}
	</StyledCallToAction>
)
export const StyledLargeCallToAction = styled.span`
	font-size: ${theme.fontSizes.p3};
	& svg {
		width: 20px;
		height: 20px;
		margin-bottom: -4px;
	}
	${props => callToAction(props)}
`
// Wrap CTA text children in spans so we can style them with css
export const LargeCallToAction = ({ children, ...props }) => (
	<StyledLargeCallToAction {...props}>
		{React.Children.map(children, child => (typeof child === 'string' ? <span>{child}</span> : child))}
	</StyledLargeCallToAction>
)

export const CallToActionPageSection = ({ children, ...props }) => (
	<SmallPageSection>
		<CallToAction {...props}>{children}</CallToAction>
	</SmallPageSection>
)

const StyledDynamicTextAlignment = styled.div`
	${theme.breakpoints.tablet.overAndExcluding} {
		text-align: center;
		> * {
			margin-left: auto;
			margin-right: auto;
		}
	}
`
export const DynamicTextAlignment = ({ children }) => (
	<StyledDynamicTextAlignment>{children}</StyledDynamicTextAlignment>
)

export const H2CallToAction = styled(CallToAction)`
	color: ${theme.colors.h2Color};
	:hover {
		color: ${theme.colors.h2Color};
	}
	text-decoration: underline;
	font-size: ${({ theme }) => theme.fontSizes.h2};
	& svg {
		margin-bottom: -2px;
	}
`

export const UnderlinedA = styled.a`
	text-decoration: underline;
	cursor: pointer;
	:hover {
		text-decoration: underline;
	}
`

export const UnderlinedSpan = styled.span`
	text-decoration: underline;
	cursor: pointer;
	:hover {
		text-decoration: underline;
	}
`

export const UnderlinedLink = styled(Link)`
	text-decoration: underline;
	cursor: pointer;
	:hover {
		text-decoration: underline;
	}
`

export const Breadcrumbs = styled(P4)`
	letter-spacing: 0.43px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	a {
		${textColors}
	}
`

// Aligns the list's items to the left with no left padding
export const LeftAlignedOrderedList = styled.ol`
	padding-inline-start: 17px;
`
// Aligns the list's items to the left with no left padding
export const LeftAlignedUnorderedList = styled.ul`
	padding-inline-start: 17px;
	> li {
		padding-left: ${theme.paddings.XXSmall};
	}
`

// Removes dots from list
export const ListWithoutDots = styled.ol`
	list-style: none;
	padding-inline-start: 0;
`

export const CheckboxList = styled.div`
	ul {
		padding-inline-start: 0;
		list-style: none;
		margin-top: 0;
		margin-bottom: 0;
		li {
			display: flex;
			:not(:last-child) {
				margin-bottom: ${theme.margins.XSmall};
			}
		}
		li:before {
			content: '✓';
			padding-right: 15px;
			font-size: 22px;
		}
	}
`

export const StyledReactMarkdown = styled(ReactMarkdown)`
	// react-markdown converts linebreaks of a paragraph into their own paragraphs, so separate them with margin instead
	p:not(:last-child) {
		margin-bottom: 1em;
	}
	// On the rest of the site, anchor tags that are in a paragraph tag automatically get underlined. With markdown however,
	// we can't always choose whether to have a paragraph tag or not, so always underline anchor tags instead.
	${props => props.textColor && `color: ${theme.colors[props.textColor]};`};
	a {
		${props => props.textColor && `color: ${theme.colors[props.textColor]};`};
		text-decoration: underline;
	}
	a:active,
	a:hover {
		outline: 0;
		text-decoration: none;
	}
`
export const Markdown = props => <StyledReactMarkdown {...props} />
