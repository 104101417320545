import React, { useContext } from 'react'
import UserContext from '../../../contexts/user/UserContext'
import ConditionalLink from '../other/ConditionalLink'

const EjblaLogo = ({ fillColor = '#111111', onClick, disableClick, openedInPwa = false }) => {
	const { userState } = useContext(UserContext)
	const rootPath = userState.pwa.rootPath
	return (
		<ConditionalLink
			to={rootPath}
			onClick={onClick}
			style={{ display: 'flex', pointerEvents: disableClick ? 'none' : 'auto' }}
		>
			<svg width="72" height="35" xmlns="http://www.w3.org/2000/svg">
				<title>ejblalogo</title>
				<text fontFamily="Chap" x="0" y="27" fill={fillColor} fontSize="35" fontStyle="normal" fontWeight="normal">
					ejbla
				</text>
			</svg>
		</ConditionalLink>
	)
}

export default EjblaLogo
