import PropTypes from 'prop-types'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const Spinner = styled.div`
	display: inline-block;
	position: relative;
	width: ${props => (props.width ? props.width + 12 + 'px' : '62px')};
	height: ${props => (props.width ? props.width + 12 + 'px' : '62px')};
	& div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: ${props => (props.width ? props.width + 'px' : '50px')};
		height: ${props => (props.width ? props.width + 'px' : '50px')};
		margin: 6px;
		border: ${props => (props.weight ? props.weight + 'px' : '6px')} solid;
		border-radius: 50%;
		animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #0000002b transparent transparent transparent;
	}
	& div:nth-child(1) {
		animation-delay: -0.45s;
		border-color: black transparent transparent transparent;
	}
	& div:nth-child(2) {
		animation-delay: -0.3s;
		border-color: #0000008c transparent transparent transparent;
	}
	& div:nth-child(3) {
		animation-delay: -0.15s;
		border-color: #00000059 transparent transparent transparent;
	}
`

const LoadingSpinner = props => (
	<Spinner style={props.optionalStyle} width={props.width} weight={props.weight}>
		<div />
		<div />
		<div />
		<div />
	</Spinner>
)

LoadingSpinner.propTypes = {
	optionalStyle: PropTypes.object,
}

export default LoadingSpinner
