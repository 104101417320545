import { createGlobalStyle } from 'styled-components'
import { theme } from '../../utils/theme'

const GeneralStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
    &.disable_scroll {
      &::-webkit-scrollbar {
        display: none;
      }
      overflow: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  body {
    background-color: ${theme.colors.white};
    margin: 0;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-y; // Disable pinch zoom etc in iOS
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
`

export default GeneralStyle
