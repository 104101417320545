import React from 'react'
import { useTranslation } from 'react-i18next-new'
import axios from 'axios'
import styled from 'styled-components'
import useRoutes from '../../../hooks/useRoutes'

const LanguageButton = styled.span`
	text-decoration: ${({ selected }) => (selected ? 'none' : 'underline')};
	cursor: ${({ selected }) => (selected ? 'normal' : 'pointer')};
`
const LanguageSwitcher = ({ color = 'white' }) => {
	const { t, i18n } = useTranslation()
	const routes = useRoutes()
	const currentLocale = i18n.language
	const changeLanguage = newLocale => {
		if (currentLocale === newLocale) return
		// Swedish locale in <link> is 'sv-default' instead of 'sv'
		const queryLocale = newLocale === 'sv' ? 'sv-default' : newLocale
		// Get the localized URL from the SEO <link rel=alternate hreflang.. /> since they are always correctly set by react-header.
		const linkElement = document.querySelector(`link[rel=alternate][hreflang=${queryLocale}]`)
		// Post to server to change locale in cookie, then change the URL in browser. Change to root path as a fallback
		// TODO: After cookie is set, change locale with client side routing by updating i18n and rerouting with useHistory so we don't need to reload the page
		axios
			.post(routes.system.changeLocale.url, {
				change_to_locale: newLocale,
			})
			.then(() => (location = linkElement ? linkElement.href + location.search : '/' + newLocale))
	}
	return (
		<div style={{ color }}>
			{i18n.language === 'sv' ? 'Språk' : 'Language'}:&nbsp;&nbsp;
			<LanguageButton onClick={() => changeLanguage('sv')} selected={currentLocale === 'sv'}>
				SV
			</LanguageButton>
			&nbsp;/&nbsp;
			<LanguageButton onClick={() => changeLanguage('en')} selected={currentLocale === 'en'}>
				EN
			</LanguageButton>
		</div>
	)
}

export default LanguageSwitcher
