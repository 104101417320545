import { createGlobalStyle } from 'styled-components'

const FormStyle = createGlobalStyle`
  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit; /* 1 */
    font: inherit; /* 2 */
    margin: 0; /* 3 */
  }

  /* Added to avoid iOS bug where textarea children of containers with "-webkit-overflow-scrolling: touch" (added for momentum scrolling in iOS)
	 don't render input text. iOS renders the layers in a weird manner so that the input text gets behind the textarea. This translateZ hack
	 switches on the hardware compositing mode in Chrome and creates a new layer with its own backing surface in Chrome to override iOS's own rendered layers.
	 and to get the GPU involved. For further reading please read the following article: https://aerotwist.com/blog/on-translate3d-and-layer-creation-hacks/
	*/
  textarea {
    -webkit-transform: translateZ(0px);
  }

  button {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type="button"], /* 1 */
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  input {
    line-height: normal;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box; /* 1 */
    cursor: pointer;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`

export default FormStyle
