import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import { useTranslation } from 'react-i18next-new'
import useRoutes from '../../../hooks/useRoutes'
import { Divider, SmallPageSection } from '../../reusable_components/layout/PageComponents'
import LanguageSwitcher from './LanguageSwitcher'
import { isMarketplace } from '../../../utils/applicationHelper'
import { Link } from 'react-router-dom'
import ConditionalLink from '../../reusable_components/other/ConditionalLink'
import EjblaLogo from '../../reusable_components/svg_icons/EjblaLogo'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'

const FooterWrapper = styled.div`
	padding: ${theme.paddings.large} 0;
`
const Columns = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: ${theme.margins.small};
`
const BottomSection = styled.div`
	display: flex;
	margin-top: ${theme.margins.large};
	flex-wrap: wrap;
	align-items: center;
	${theme.breakpoints.tablet.upToAndIncluding} {
		align-items: flex-start;
		flex-direction: column;
		gap: ${theme.margins.XSmall};
	}
	> span:last-child {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		${theme.breakpoints.tablet.upToAndIncluding} {
			margin-top: ${theme.margins.small};
		}
	}
`

const Footer = ({ footerLinks }) => {
	const { t, i18n } = useTranslation()
	const { categories, marketplaces, articlesLink } = footerLinks
	const routes = useRoutes()
	return (
		<FooterWrapper id="pageFooter">
			<Divider />
			<SmallPageSection>
				<Columns>
					{categories?.length > 0 && (
						<div>
							<b>{t('copy:footer.topCategories')}</b>
							{categories.map((category, key) => (
								<div key={key}>
									<Link to={category.path}>{category.title}</Link>
								</div>
							))}
						</div>
					)}
					{marketplaces?.length > 0 && (
						<div>
							<b>{t('copy:footer.marketplaces')}</b>
							{marketplaces.map((marketplace, key) => (
								<div key={key}>
									<a href={marketplace.url}>{marketplace.title}</a>
								</div>
							))}
						</div>
					)}
					<div>
						<b>{t('copy:footer.about')}</b>
						<div>
							<a href="https://help.ejbla.com/" rel="noopener noreferrer">
								{t('copy:footer.faq')}
							</a>
						</div>
						<div>
							<a href={routes.static.contact.url} rel="noopener noreferrer">
								{t('copy:footer.contact')}
							</a>
						</div>
						{articlesLink && (
							<div>
								<a href={articlesLink} rel="noopener noreferrer">
									{t('copy:footer.articles')}
								</a>
							</div>
						)}
					</div>
					<div>
						<b>{t('copy:footer.creator')}</b>
						<div>
							<ConditionalLink to={routes.static.ejblaStartPage[isMarketplace() ? 'url' : 'path']}>
								{t('copy:footer.hostWithEjbla')}
							</ConditionalLink>
						</div>
						<div>
							<a href="https://organizerhelp.ejbla.com/" rel="noopener noreferrer">
								{t('copy:footer.helpCenterExperts')}
							</a>
						</div>
						<div>
							<ConditionalLink to={routes.static.pricing[isMarketplace() ? 'url' : 'path']}>
								{t('copy:footer.price')}
							</ConditionalLink>
						</div>
					</div>
				</Columns>
			</SmallPageSection>
			<BottomSection>
				<LanguageSwitcher color={theme.colors.black} />
				<span>
					<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>&nbsp;&nbsp;·&nbsp;&nbsp;</Breakpoint>
					<a href={routes.custom.integrityPolicy.url} target="_blank" rel="nofollow noreferrer noopener">
						{t('common:documents.integrityPolicy')}
					</a>
					&nbsp;&nbsp;·&nbsp;&nbsp;
					<a href={routes.custom.termsOfUse.url} target="_blank" rel="nofollow noreferrer noopener">
						{t('common:documents.termsOfUse')}
					</a>
				</span>
				<span>
					<span>
						<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>&nbsp;&nbsp;·&nbsp;&nbsp;</Breakpoint>
						&reg;&nbsp;Ejbla AB,&nbsp;{new Date().getFullYear()}
					</span>
					<EjblaLogo />
				</span>
			</BottomSection>
		</FooterWrapper>
	)
}

export default Footer
