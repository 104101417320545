import React from 'react'
import GeneralStyle from './base/GeneralStyle'
import FormStyle from './base/FormStyle'
import TypographyStyle from './base/TypographyStyle'
import AutoSuggestStyle from './vendor/AutoSuggestStyle'
import CookieConsentStyle from './vendor/CookieConsentStyle'
import HelpScoutStyle from './vendor/HelpScoutStyle'
import DatePickerStyle from './vendor/DatePickerStyle'
import SlickStyle from './vendor/SlickStyle'
import 'react-tooltip/dist/react-tooltip.css'

const GlobalStyle = () => (
	<>
		<GeneralStyle />
		<FormStyle />
		<TypographyStyle />
		<AutoSuggestStyle />
		<CookieConsentStyle />
		<HelpScoutStyle />
		<DatePickerStyle />
		<SlickStyle />
	</>
)

export default GlobalStyle
