import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { TextSpan } from '../Typography'
import styled from 'styled-components'

export const StyledLink = styled(NavLink)`
	&.active {
		font-weight: bold;
	}
`

// Useful when needing to render a link when there is url, and not a link otherwise, since Link requires a "to" prop
const ConditionalLink = ({ to, children, disabledColor, styledLink, ...rest }) =>
	to ? (
		to.includes('https://') ? (
			<a href={to} {...rest}>
				{children}
			</a>
		) : styledLink ? (
			<StyledLink to={to} {...rest}>
				{children}
			</StyledLink>
		) : (
			<Link to={to} {...rest}>
				{children}
			</Link>
		)
	) : (
		<TextSpan textColor={disabledColor || 'lightTextColor'} {...rest}>
			{children}
		</TextSpan>
	)

export default ConditionalLink
