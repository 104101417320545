import { createGlobalStyle } from 'styled-components'
import { theme } from '../../utils/theme'
import { beaconPositionDesktop, beaconPositionMobile } from '../../utils/constants'

const HelpScoutStyle = createGlobalStyle`
  .BeaconFabButtonFrame {
    z-index: 7 !important;
    transition: bottom 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) !important;
    ${theme.breakpoints.tablet.upToAndIncluding} {
    	right: 20px !important;
    	bottom: ${beaconPositionMobile}px !important;
    }
    ${theme.breakpoints.tablet.overAndExcluding} {
    	bottom: ${beaconPositionDesktop}px !important;
    }
  }
`

export default HelpScoutStyle
