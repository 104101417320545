import styled, { css, ThemeProvider } from 'styled-components'
import React from 'react'
import { theme } from '../../../utils/theme'
import {
	centeredContent,
	divider,
	dynamicPageSection,
	largeVerticalMargin,
	noMarginForFirstAndLastChild,
	pageSectionRow,
	pageSidePadding,
	repulsiveRow,
	smallVerticalMargin,
	xSmallVerticalMargin,
	xxSmallVerticalMargin,
} from '../PageMixins'

import ReactLazyLoad from 'react-lazyload'
import Breakpoint from '../responsive/Breakpoint'
import Footer from '../../layout/footer/Footer'
import FillPageContainer from '../responsive/FillPageContainer'
import GlobalStyle from '../../../css/GlobalStyle'
import LoadingSpinner from '../other/LoadingSpinner'

// Main wrapper for pages
// Use skipSidePadding for pages that have image banners etc that needs to go out all the way to the sides.
export function PageWrapper({
	children,
	hideFooter,
	footerLinks,
	skipSidePadding,
	skipTopMargin,
	fillCurrentPageToTop,
}) {
	return (
		<div>
			<FillPageContainer>
				<PageMarginWrapper
					skipSidePadding={skipSidePadding}
					skipTopMargin={skipTopMargin}
					fillCurrentPageToTop={fillCurrentPageToTop}
				>
					{children}
				</PageMarginWrapper>
			</FillPageContainer>
			{hideFooter || (
				<PageSidePaddingWrapper style={{ position: 'relative', background: 'white' }}>
					<Footer footerLinks={footerLinks} />
				</PageSidePaddingWrapper>
			)}
		</div>
	)
}
// Wrapper to get access to global theme without the PageWrapper
export function ThemeWrapper(props) {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<>{props.children}</>
		</ThemeProvider>
	)
}

// Sets default side padding for page content
export const PageSidePaddingWrapper = styled.div`
	${({ skipSidePadding }) => (skipSidePadding ? '' : pageSidePadding)}
`
// Adds top and bottom padding to a PageSidePaddingWrapper.
// Usually used for divs with a colored background that needs to stretch to the end of the screen,
// which also has content that needs to be aligned with the rest of the page's content
export const PageSidePaddingWrapperWithTopBottomPadding = styled(PageSidePaddingWrapper)`
	padding-top: ${theme.paddings.large} !important;
	padding-bottom: ${theme.paddings.large} !important;
	${noMarginForFirstAndLastChild}
`
export const LargeSidePaddingWrapper = styled.div`
	padding: 0 ${theme.paddings.large};
	${noMarginForFirstAndLastChild}
`

export const LargePaddingWrapper = styled.div`
	padding: ${theme.paddings.large};
	${noMarginForFirstAndLastChild}
`
export const SmallPaddingWrapper = styled.div`
	padding: ${theme.paddings.small};
	${noMarginForFirstAndLastChild}
`
export const SmallSidePaddingWrapper = styled.div`
	padding: 0 ${theme.paddings.small};
	${noMarginForFirstAndLastChild}
`
export const ContentSideBarWrapper = styled(PageSidePaddingWrapper)`
	${({ isOpenedInContentSideBar }) =>
		isOpenedInContentSideBar
			? css`
					${theme.breakpoints.tablet.overAndExcluding} {
						padding-left: ${theme.paddings.small};
						padding-right: ${theme.paddings.small};
					}
				`
			: ''}
`

// Adds default page top and bottom margin to PageSidePaddingWrapper
const PageMarginWrapper = styled(PageSidePaddingWrapper)`
	margin-top: ${({ fillCurrentPageToTop, skipTopMargin }) =>
		fillCurrentPageToTop
			? 0 // 0 margin to top of page
			: skipTopMargin
				? 55 // height of header
				: 65}px; // height of header, plus some more
`

export const LazyLoad = ({ children, height, offset, ...lazyLoadProps }) => (
	<ReactLazyLoad
		height={height || 300}
		offset={offset ?? 100}
		once
		resize
		placeholder={
			<CenteredPageSection>
				<LoadingSpinner />
			</CenteredPageSection>
		}
		{...lazyLoadProps}
	>
		{children}
	</ReactLazyLoad>
)

// Divides large vertical page sections using vertical margin
export const StyledPageSection = styled.div`
	${largeVerticalMargin}
	${noMarginForFirstAndLastChild}
	width: 100%;
`
export const PageSection = ({ children, lazyLoad, ...lazyLoadProps }) =>
	lazyLoad ? (
		<LazyLoad {...lazyLoadProps}>
			<StyledPageSection>{children}</StyledPageSection>
		</LazyLoad>
	) : (
		<StyledPageSection>{children}</StyledPageSection>
	)

// Page section but with added side padding. Useful if no parent SidePaddingWrapper exists
export const PageSectionWithSidePadding = styled(PageSidePaddingWrapper)`
	${largeVerticalMargin}
`
// Small page section, but with flex for multiple items in one row.
export const StyledPageSectionRow = styled(StyledPageSection)`
	${({
		children,
		alignItems,
		centered,
		maxWidth,
		breakpoint,
		childrenPerRow,
		childrenPerRowDesktop,
		childrenPerRowMobile,
	}) =>
		pageSectionRow(
			{ all: theme.margins.largeInt },
			children.length,
			alignItems,
			centered,
			childrenPerRow,
			childrenPerRowDesktop,
			childrenPerRowMobile,
			maxWidth,
			breakpoint,
		)}
`
export const PageSectionRow = ({ children, lazyLoad, alignItems, centered, maxWidth, breakpoint, ...lazyLoadProps }) =>
	lazyLoad ? (
		<LazyLoad {...lazyLoadProps}>
			<StyledPageSectionRow alignItems={alignItems} centered={centered} maxWidth={maxWidth} breakpoint={breakpoint}>
				{children}
			</StyledPageSectionRow>
		</LazyLoad>
	) : (
		<StyledPageSectionRow alignItems={alignItems} centered={centered} maxWidth={maxWidth} breakpoint={breakpoint}>
			{children}
		</StyledPageSectionRow>
	)
// Divides small vertical page sections using vertical margin
export const SmallPageSection = styled.div`
	width: 100%;
	${smallVerticalMargin}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}
`
// Small page section, but with flex for multiple items in one row.
export const StyledSmallPageSectionRow = styled(SmallPageSection)`
	${({
		children,
		alignItems,
		centered,
		maxWidth,
		breakpoint,
		childrenPerRow,
		childrenPerRowDesktop,
		childrenPerRowMobile,
	}) =>
		pageSectionRow(
			{ all: theme.margins.smallInt },
			children.length,
			alignItems,
			centered,
			childrenPerRow,
			childrenPerRowDesktop,
			childrenPerRowMobile,
			maxWidth,
			breakpoint,
		)}
`
export const SmallPageSectionRow = ({
	children,
	lazyLoad,
	alignItems,
	centered,
	maxWidth,
	breakpoint,
	...lazyLoadProps
}) =>
	lazyLoad ? (
		<LazyLoad {...lazyLoadProps}>
			<StyledSmallPageSectionRow
				alignItems={alignItems}
				centered={centered}
				maxWidth={maxWidth}
				breakpoint={breakpoint}
			>
				{children}
			</StyledSmallPageSectionRow>
		</LazyLoad>
	) : (
		<StyledSmallPageSectionRow alignItems={alignItems} centered={centered} maxWidth={maxWidth} breakpoint={breakpoint}>
			{children}
		</StyledSmallPageSectionRow>
	)
// A PageSection but with more flexible margin options
export const StyledDynamicPageSection = styled.div`
	${({ margins = {}, breakpoint }) => dynamicPageSection(margins, breakpoint)}
`
export const DynamicPageSection = ({ children, margins, lazyLoad, breakpoint, ...lazyLoadProps }) =>
	lazyLoad ? (
		<LazyLoad {...lazyLoadProps}>
			<StyledDynamicPageSection margins={margins} breakpoint={breakpoint}>
				{children}
			</StyledDynamicPageSection>
		</LazyLoad>
	) : (
		<StyledDynamicPageSection margins={margins} breakpoint={breakpoint}>
			{children}
		</StyledDynamicPageSection>
	)
export const StyledDynamicPageSectionRow = styled.div`
	${({ margins = {}, breakpoint }) => dynamicPageSection(margins, breakpoint)}
	${({
		children,
		alignItems,
		centered,
		maxWidth,
		breakpoint,
		margins = {},
		childrenPerRow,
		childrenPerRowDesktop,
		childrenPerRowMobile,
		reversedOnMobile,
		preserveWidth,
	}) => {
		// If there is only one child, put it in an array so we can do reduce on it
		if (!children.length) children = [children]
		// Only count a child that is a react components
		const numberOfChildren = children.reduce((result, child) => {
			if (typeof child === 'object') result++
			return result
		}, 0)
		return pageSectionRow(
			margins,
			numberOfChildren,
			alignItems,
			centered,
			childrenPerRow,
			childrenPerRowDesktop,
			childrenPerRowMobile,
			maxWidth,
			breakpoint,
			reversedOnMobile,
			preserveWidth,
		)
	}}
`
// A PageSectionRow but with more flexible margin options
export const DynamicPageSectionRowBreakpoints = ({ children, breakpoint = 'tablet', ...props }) => (
	<>
		<Breakpoint breakpoint={theme.breakpoints[breakpoint].upToAndIncluding}>
			<StyledDynamicPageSectionRow {...props} breakpoint={breakpoint}>
				{children}
			</StyledDynamicPageSectionRow>
		</Breakpoint>
		<Breakpoint breakpoint={theme.breakpoints[breakpoint].overAndExcluding}>
			<StyledDynamicPageSectionRow {...props} breakpoint={breakpoint}>
				{children}
			</StyledDynamicPageSectionRow>
		</Breakpoint>
	</>
)
export const DynamicPageSectionRow = ({
	children,
	margins,
	lazyLoad,
	alignItems,
	centered,
	maxWidth,
	breakpoint,
	childrenPerRow,
	childrenPerRowDesktop,
	childrenPerRowMobile,
	id,
	reversedOnMobile,
	preserveWidth,
	...lazyLoadProps
}) =>
	lazyLoad ? (
		<LazyLoad {...lazyLoadProps}>
			<DynamicPageSectionRowBreakpoints
				id={id}
				alignItems={alignItems}
				centered={centered}
				maxWidth={maxWidth}
				breakpoint={breakpoint}
				margins={margins}
				childrenPerRow={childrenPerRow}
				childrenPerRowDesktop={childrenPerRowDesktop}
				childrenPerRowMobile={childrenPerRowMobile}
				reversedOnMobile={reversedOnMobile}
				preserveWidth={preserveWidth}
			>
				{children}
			</DynamicPageSectionRowBreakpoints>
		</LazyLoad>
	) : (
		<DynamicPageSectionRowBreakpoints
			id={id}
			alignItems={alignItems}
			centered={centered}
			maxWidth={maxWidth}
			breakpoint={breakpoint}
			margins={margins}
			childrenPerRow={childrenPerRow}
			childrenPerRowDesktop={childrenPerRowDesktop}
			childrenPerRowMobile={childrenPerRowMobile}
			reversedOnMobile={reversedOnMobile}
			preserveWidth={preserveWidth}
		>
			{children}
		</DynamicPageSectionRowBreakpoints>
	)
export const ContentRow = ({ children, alignItems, title, bottomContent, margins, extraTitleContent, ...rest }) => (
	<>
		{title && (
			<DynamicPageSection margins={{ top: theme.margins.smallInt, bottom: theme.margins.smallInt }}>
				<RepulsiveTitleSection>
					<h2>{title}</h2>
					{extraTitleContent}
				</RepulsiveTitleSection>
			</DynamicPageSection>
		)}
		<DynamicPageSectionRow
			margins={{
				top: theme.margins.smallInt,
				bottom: theme.margins.largeInt,
				inner: theme.margins.smallInt,
				...margins,
			}}
			alignItems={alignItems}
			{...rest}
		>
			{children}
		</DynamicPageSectionRow>
		{bottomContent && (
			<DynamicPageSection margins={{ top: -theme.margins.smallInt, bottom: theme.margins.largeInt }}>
				{bottomContent}
			</DynamicPageSection>
		)}
	</>
)
export const PageTopSection = ({ children }) => <CenteredDynamicPageSection>{children}</CenteredDynamicPageSection>
// Small page section, but with flex for two repulsive items in a row
// i.e the first object floats to the left and the second to the right
export const SmallPageSectionRepulsiveRow = styled(SmallPageSection)`
	${repulsiveRow}
`
export const PageSectionRepulsiveRow = styled(StyledPageSection)`
	${repulsiveRow}
`
export const RepulsiveRow = styled.div`
	${repulsiveRow}
`
// Divides small vertical page sections using vertical margin
export const XSmallPageSection = styled.div`
	width: 100%;
	${xSmallVerticalMargin}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}
`
// Divides small vertical page sections using vertical margin
export const XXSmallPageSection = styled.div`
	width: 100%;
	${xxSmallVerticalMargin}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}
`
// A plain gray line to separate to vertical elements
export const Divider = styled.div`
	${props => divider(props)}
`
// A plain gray line to separate to vertical elements. The span allows it to be a child of e.g. a <p>
export const SpanDivider = styled.span`
	display: inline-flex;
	${props => divider(props)}
`
// A plain gray line to separate to vertical elements
export const SmallPageSectionDivider = styled(SmallPageSection)`
	${props => divider(props)}
`
// A plain gray line to separate to vertical elements
export const LargePageSectionDivider = styled(StyledPageSection)`
	${props => divider(props)}
`
// Row to contain multiple form inputs
export const FormSectionRow = styled(SmallPageSection)`
	${props => pageSectionRow({ all: theme.margins.smallInt }, props.children.length)}
	align-items: flex-end;
	> * {
		margin-top: 0;
		margin-bottom: 0;
	}
`
// Wrapper around forms in OverlayModal.jsx
export const OverlayModalFormWrapper = styled.div`
	padding: 0 ${theme.paddings.large} ${theme.paddings.large};
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}
	> form:first-child {
		> *:first-child {
			margin-top: 0;
		}
	}
`
// Row for when only one input and button is needed. Sticks them together, makes the input take up the remaining space and makes button same height as input
export const GluedInputAndButtonRow = styled(SmallPageSection)`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	> * {
		margin-top: 0;
		margin-bottom: 0;
	}
	> *:first-child {
		flex: 1;
	}
	> *:last-child {
		height: 43px;
	}
`
// A section with centered content
export const CenteredSection = styled.div`
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}
	${centeredContent}
`
// A page section with centered content
export const CenteredDynamicPageSection = styled(StyledDynamicPageSection)`
	${centeredContent}
`
// A page section with centered content
export const CenteredPageSection = styled(StyledPageSection)`
	${centeredContent}
`
// A small page section with centered content
export const CenteredSmallPageSection = styled(SmallPageSection)`
	${centeredContent}
`
// Can be used to overwrite title margin to allow for other elements next to title
export const TitleSection = styled.div`
	display: flex;
	margin-bottom: ${({ noMargin }) => (noMargin ? 0 : theme.margins.small)};
	& h1,
	& h2,
	& h3 {
		margin-bottom: 0;
	}
	> *:not(:first-child) {
		margin-left: ${theme.margins.XSmall};
	}
	align-items: center;
`
// Title section, but it repulses content so that title floats to left and the other item to the right
export const RepulsiveTitleSection = styled(TitleSection)`
	justify-content: space-between;
`

export const BorderBox = styled.div`
	border: 1px solid ${props => props.theme.colors.lightBlueGray};
`
export const ShadowedBorderBox = styled(BorderBox)`
	box-shadow: 0 5px 15px 0 ${props => props.theme.colors.lightBlueGray};
`

const StyledGrayBox = styled(LargePaddingWrapper)`
	width: 100%;
	margin-top: ${theme.margins.XXSmall};
	border-radius: 2px;
	background: ${props => (props.backgroundColor ? props.backgroundColor : theme.colors.lightGray)};
	${theme.breakpoints.phone.upToAndIncluding} {
		padding: ${theme.paddings.small};
	}
	${props =>
		props.textColor
			? css`
					* {
						color: ${props.textColor};
					}
				`
			: ''}
`
export const GrayBox = ({ backgroundColor, textColor, children, ...rest }) => (
	<StyledGrayBox backgroundColor={backgroundColor} textColor={textColor} {...rest}>
		{children}
	</StyledGrayBox>
)

export const GrayBoxesContainer = styled.div`
	max-width: ${theme.widths.narrowPageMaxWidth};
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	> * {
		width: 100%;
		margin-top: ${theme.margins.XXSmall};
	}
`

export const ColoredContainer = styled.div`
	background: ${theme.colors.lightGray};
	padding-top: ${theme.paddings.large};
	padding-bottom: ${theme.paddings.large};
	${theme.breakpoints.tablet.overAndExcluding} {
		${PageSidePaddingWrapper} {
			> h2,
			> p {
				text-align: center;
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
`

const StyledLeftAlignedFlexBox = styled.div`
	display: flex;
	align-items: ${({ alignItems }) => alignItems};
	> :not(:first-child) {
		margin-left: ${({ innerMargin }) => innerMargin || theme.margins.XSmall};
	}
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
`
export const LeftAlignedFlexBox = ({ children, innerMargin, onClick, alignItems = 'center' }) => (
	<StyledLeftAlignedFlexBox innerMargin={innerMargin} onClick={onClick} alignItems={alignItems}>
		{children}
	</StyledLeftAlignedFlexBox>
)
